
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sites from './components/admins/Sites';
import EditionSites from './components/admins/EditionSites';
import AddSites from './components/admins/AddSites';
import Users from './components/admins/Users';
import AddUsers from './components/admins/AddUsers';
import Membre from './components/admins/Membre';
import Examens from './components/admins/Examens';
import Gains from './components/admins/Gains';
import EditionUsers from './components/admins/EditionUsers';
import DossierRecu from './components/admins/DossierRecu';
import Membres from './components/admins/Membres';
import AddExamen from './components/admins/AddExamen';
import EditionExamens from './components/admins/EditionExamens';
import AddGains from './components/admins/AddGains';
import EditionGains from './components/admins/EditionGains';
import EditionMembre from './components/admins/EditionMembre';
import Notifications from './components/admins/Notifications';
import AddNotification from './components/admins/AddNotification';
import EditionNotification from './components/admins/EditionNotification';
import ModelCompteRendu from './components/admins/ModelCompteRendu';
import Statistiques from './components/admins/Statistiques';
import Agenda from './components/admins/Agenda';
import Contrats from './components/admins/Contrats';
import AddContrats from './components/admins/AddContrats';
import Manipulateurs from './components/admins/Manipulateurs';
import AddManipulateurs from './components/admins/AddManipulateurs';
import EditionManipulateurs from './components/admins/EditionManipulateurs';
import EditionContrat from './components/admins/EditionContrat';
import PDFViewer from './components/admins/PdfViewer';
import Login from './components/Login';
import { ThemeProvider } from './context/ContextHeader';
import LectureNotification from './components/admins/LectureNotification';
import '/node_modules/primeflex/primeflex.css';
import Abonnement from './components/admins/Abonnement';
import DossiersTransfere from './components/admins/DossiersTransfere';
import Resultats from './components/admins/Resultat';
import Apercu from './components/admins/Apercu';
import { PrimeReactProvider } from 'primereact/api';
import Modalites from './components/admins/Modalites';
import AddCompteRendu from './components/admins/AddCompteRendu';
import EditionMrc from './components/admins/EditionMrc';
import EditionDossiers from './components/admins/EditionDossiers';
import Corbeille from './components/admins/Corbelle';
import ApercuMcr from './components/admins/ApercuMcr';
import DetailFolder from './components/admins/DetailFolder';
import Archive from './components/admins/Archive';
import ConfigGains from './components/admins/ConfigGains';
import UserLoginCreate from './components/admins/UserLoginCreate';
function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <ThemeProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/resultats" element={<Resultats />} />
            <Route path="/dossiers_recus" element={<DossierRecu />} />
            <Route path="/sites" element={<Sites />} />
            <Route path="/sites_edit/:id" element={<EditionSites />} />
            <Route path="/addsites" element={<AddSites />} />

            <Route path="/users" element={<Users />} />
            <Route path="/add_users" element={<AddUsers />} />
            <Route path="/users_edit/:id" element={<EditionUsers />} />

            <Route path="/membres" element={<Membres />} />
            <Route path="/editionMembre/:id" element={<EditionMembre />} />
            <Route path="/addMembre" element={<Membre />} />

            <Route path="/examens" element={<Examens />} />
            <Route path="/addExamen" element={<AddExamen />} />
            <Route path="/editionExamen/:id" element={<EditionExamens />} />

            <Route path="/gains" element={<Gains />} />
            <Route path="/editionGains/:id" element={<EditionGains />} />
            <Route path="/addGains" element={<AddGains />} />

            <Route path="/notifications" element={<Notifications />} />
            <Route path="/lire-notification/:id" element={<Notifications />} />
            <Route path="/addNotifications" element={<AddNotification />} />
            <Route path="/editionNotification/:id" element={<EditionNotification />} />
            <Route path="/lireNotification" element={<LectureNotification />} />
            <Route path="/modelcompterendu" element={<ModelCompteRendu />} />
            <Route path="/addCompteRendu" element={<AddCompteRendu />} />
            <Route path="/editionMcr/:id" element={<EditionMrc />} />
            <Route path="/apercuMcr/:id" element={<ApercuMcr />} />
            <Route path="/corbeille" element={<Corbeille />} />
            {/* <Route path="/editionDossiers/" element={<EditionDossiers />} /> */}
            <Route path="/editionDossiers/:id" element={<EditionDossiers />} />
            <Route path="/dossiersTransferes" element={<DossiersTransfere />} />
            <Route path="/statistiques" element={<Statistiques />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/addContrat" element={<AddContrats />} />
            <Route path="/contrats" element={<Contrats />} />
            <Route path="/modifier_contrat/:id" element={<EditionContrat />} />
            <Route path="/member_detail_folder/:id" element={<DetailFolder />} />
            <Route path="/manipulateurs" element={<Manipulateurs />} />
            <Route path="/addManipulateurs" element={<AddManipulateurs />} />
            <Route path="/editionManipulateur/:id" element={<EditionManipulateurs />} />
            <Route path="/pdfViewer/:id" element={<PDFViewer />} />
            <Route path="/modalite" element={<Modalites />} />
            <Route path="/apercu/:id" element={<Apercu />} />
            <Route path="/abonnement" element={<Abonnement />} />
            <Route path="/archives" element={<Archive />} />
            <Route path="/config-gains" element={<ConfigGains />} />
            <Route path="/create-compte" element={<UserLoginCreate />} />
         
          </Routes>
        </ThemeProvider>
      </Router>

    </PrimeReactProvider>
  );
}

export default App;
