import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { URL_AXIOS, URL_MAIN } from '../../URL/URL';
import { AiFillFilePdf, AiFillFileZip } from 'react-icons/ai';
import { FaFileAlt, FaFileExcel, FaFileWord } from 'react-icons/fa';
import { ContextMenu } from 'primereact/contextmenu';
import { ThemeContext } from '../../context/ContextHeader';

function ReceiveMessageComponent({ user_id_sender, user_id_reception }) {
    // const [notificationsAll, setNotificationsAll] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const cm = useRef(null);

    const {
        setDisplay_last_message,
        setCountNotif,
        setBoolLoadingMessage,
        notificationsAll,
        setNotificationsAll,
        setTextContent,
        noti_id,
        setNoti_id
    } = useContext(ThemeContext);
    const liste_notification = async (id) => {

        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${id}`);
            if (response.data.status === 200) {
                setDisplay_last_message(response.data.display_last_message)
                setCountNotif(response.data.notificationsAllCount);
                setBoolLoadingMessage(false);

            } else {
                setBoolLoadingMessage(false);
            }
        } catch (Error) {
            setBoolLoadingMessage(false);
        }
    }
    const liste_notificationLoad_recep = useCallback(async (id_user_sender, id_user_recep) => {
        try {
            const response = await axios.get(URL_AXIOS + `laodMessageNotification/${id_user_sender}/${id_user_recep}`);
            if (response.data.status === 200) {
                setNotificationsAll(response.data.display_last_message);

            } else {
                alert("Erreur ")
            }
        } catch (Error) {
            alert("Console")
            console.log("Erreur");
        }
    }, [setNotificationsAll]);
    const onRightClick = (event, id) => {
        event.preventDefault();
        if (cm.current) {
            setSelectedId(id);
            cm.current.show(event);
        }
    };

    const delete_notification = async (id_notification) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_notifications/${id_notification}`);
            if (response.data.status === 200) {

                liste_notificationLoad_recep(user_id_sender, user_id_reception);
                liste_notification(user_id_reception);


            }
        } catch (Error) {

        }

    }

    const handleDownload = (e, val) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = val;
        downloadLink.target = "_blank";
        downloadLink.click();
    };

    const ChatMessages = ({ messages }) => {
        return (
            <div className=''>
                {messages.length === 0 && (
                    <div className='flex justify-center items-center'>
                        Veuillez commencer une nouvelle discussion...
                    </div>
                )}

                {messages.map((message, index) => {
                    const isSent = message.isSent;
                    const content = message.DataUsers.contenu;
                    const file = message.DataUsers.fichier_notification;

                    return (
                        <div
                            key={index}
                            className={`flex ${isSent ? 'justify-start' : 'justify-end'} mt-2`}

                        >
                            <div
                                onContextMenu={(e) => onRightClick(e, message.DataUsers.id_notification)}
                                className={`${isSent ? 'bg-gray-100 ' : 'bg-[#4ecdc4] text-white'
                                    } p-3 rounded-lg max-w-[60%]`}
                                style={{ wordWrap: 'break-word' }}
                            >
                                <p>{content}</p>
                                {file && <div className="file-preview">{renderFilePreview(file)}</div>}
                                <p className={`${isSent ? 'text-black' : 'text-white'} float-right`}>{message.DataUsers.created_at}</p>
                            </div>

                        </div>

                    );
                })}
                <ContextMenu model={items} ref={cm} breakpoint="767px" onHide={() => setSelectedId(undefined)} />
            </div>
            
        );

        
    };

    const renderFilePreview = (name) => {
        if (!name) return null;
        const filename = URL_MAIN + "notifications_files/" + name;
        // Get the file extension
        const fileExtension = name.split('.').pop().toLowerCase();

        if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
            return (
                <img
                    src={`${URL_MAIN + "notifications_files"}/${name}`} // Adjust the path based on your server setup
                    alt="Preview"
                    className="img-preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
            );
        } else if (fileExtension === 'pdf') {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <AiFillFilePdf size={50} color="red" />

                </div>
            );
        } else if (['rar', 'zip', '7z'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <AiFillFileZip size={50} color="blue" />

                </div>
            );
        } else if (['doc', 'docx'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileWord size={50} color="blue" />
                </div>
            );
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileExcel size={50} color="green" />
                </div>
            );
        } else {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileAlt size={50} color="gray" />

                </div>
            );
        }
    };
    const load_data_editing = async (id) => {

        try {
            const response = await axios.get(URL_AXIOS + `get_notifications_by_id/${id}`);
            if (response.data.status === 200) {
                setTextContent(response.data.notifications.contenu);

            } else {

            }
        } catch (Error) {

        }
    }
    const items = [
        {
            label: 'Modifier',
            icon: 'pi pi-file-edit',
            command: async () => {
                if (selectedId) {
                    setNoti_id(selectedId);
                    await load_data_editing(selectedId)
                } else {

                }

            }


        }
        ,
        {
            separator: true
        },

        {
            label: 'Supprimer',
            icon: 'pi pi-trash',
            command: () => {
                if (selectedId) {

                    delete_notification(selectedId);
                } else {

                }

            }
        }
    ];

    useEffect(() => {
        liste_notificationLoad_recep(user_id_sender, user_id_reception);
    }, [user_id_sender, user_id_reception])
    return (
        // notificationsAll.map((noti, index) => {
        //     const isReceived = noti.DataUsers?.user_id_sender == user_id_sender; // Check if the message is received

        //     return (
        //         <div key={index} className={`flex ${isReceived ? 'justify-end' : 'justify-start'} mt-2`}>

        //             {/* Display Received Messages */}
        //             {isReceived && (
        //                 <div onContextMenu={(e) => onRightClick(e, noti.DataUsers.id_notification)} className="flex flex-col bg-gray-100 h-auto w-[60%] rounded p-[10px]">
        //                     <div>
        //                         {noti.DataUsers.fichier_notification && renderFilePreviewLoad(noti.DataUsers.fichier_notification)}
        //                     </div>
        //                     <p className='text-wrap break-words'>{noti.DataUsers.contenu}</p>

        //                     <p className="ml-[70%] mt-[10px] text-gray-500 text-sm">{noti.DataUsers.created_at}</p>
        //                 </div>
        //             )}



        //             {/* Display Sent Messages
        //             {isSent && (
        //                 <div onContextMenu={(e) => onRightClick(e, noti.DataUsers.id_notification)} className="flex justify-end mt-[10px]">
        //                     <div className="bg-[#4ecdc4] h-auto w-[60%] rounded p-[10px]">
        //                         <div>
        //                             {noti.DataUsers.fichier_notification && renderFilePreviewLoad(noti.DataUsers.fichier_notification)}
        //                         </div>
        //                         <p className='text-wrap break-words'>{noti.DataUsers.contenu}</p>

        //                         <p className="ml-[70%] mt-[10px] text-gray-500 text-sm">{noti.DataUsers.created_at}</p>
        //                     </div>
        //                 </div>
        //             )} */}

        //             <ContextMenu model={items} ref={cm} breakpoint="767px" onHide={() => setSelectedId(undefined)} />
        //         </div>
        //     );
        // })
        <ChatMessages messages={notificationsAll} />
    )
}

export default ReceiveMessageComponent
