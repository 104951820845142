
// const links="http://127.0.0.1:8000";

// export const URL_AXIOS=`${links}/api/`;
// export const URL_FOLDER_API=`${links}/sites/`;
// export const URL_ENTETE_SITE=`${links}/sites/`;
// export const URL_MAIN=`${links}/`;
// export const URL_SIGNATURE=`${links}/`;
// export const urlBulettin=`${links}/sites/`;

// export const URL_FOLDER_API="http://127.0.0.1:8000/sites/";
// export const URL_ENTETE_SITE="http://127.0.0.1:8000/sites/";
// export const URL_MAIN="http://127.0.0.1:8000/";
// export const URL_SIGNATURE="http://127.0.0.1:8000/";
// export const urlBulettin="http://127.0.0.1:8000/sites/";

export const URL_AXIOS="https://teratex.e-metech.com/api/";
export const URL_FOLDER_API="https://teratex.e-metech.com/sites/";
export const URL_ENTETE_SITE="https://teratex.e-metech.com/sites/";
export const URL_MAIN="https://teratex.e-metech.com/";
export const URL_SIGNATURE="https://teratex.e-metech.com/";
export const urlBulettin="https://teratex.e-metech.com/sites/";
