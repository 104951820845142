import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMemo } from 'react';
import axios from 'axios';
import { URL_AXIOS, URL_MAIN } from '../../URL/URL';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useContext } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Dialog } from 'primereact/dialog';
import { ThemeContext } from '../../context/ContextHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
function Contrats(props) {
    const navigate = useNavigate();
    const { sites, setSites, membres, setMembres, siteMembre, setSiteMembre } = useContext(ThemeContext);
    //const [siteMembre, setSiteMembre] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = siteMembre.slice(firstIndex, lastIndex);
    const npage = Math.ceil(siteMembre.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const member_id = sessionStorage.getItem("membre_id");
    const [visible, setVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileTwo, setSelectedFileTwo] = useState(null);
    const [site_id, setSite_id] = useState(0);
    const [membre_id, setMembre_id] = useState(0);
    const [pourcentage, setPourcentage] = useState('');
    const [visibleM, setVisibleM] = useState(false);
    const [path, setPath] = useState("");
    const [path_two, setPathTwo] = useState("");
    const [contratId, setContratId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [sitesData, setSitesData] = useState([]);
    const [centreSites, setCentreSites] = useState([]);
    const [sitecentre_id, setSitecentre_id] = useState(null);
    const [centresoins_id, setcentresoins_id] = useState(null);
    const [centreSitesData, setCentreSitesData] = useState([]);

    const handleTabView = (e) => {

        setActiveIndex(e.index);
    }
    const onSelectSiteId = (id) => {
        if (!id) {
            console.error("Invalid ID provided");
            return;
        }
        const filteredData = sitesData.filter(item => item.id !== id.id);
        setCentreSites(filteredData);
        console.log(filteredData);
    };


    const onSelectSiteLoad = (id) => {
        if (!id) {
            console.error("Invalid ID provided");
            return;
        }

        const filteredData = sitesData.filter(item => item.id !== id.id);
        setSitesData(filteredData);
        console.log("--------------------------------------")
        console.log(filteredData);
    };
    // const onSelectSiteId = (id) => {


    //     const filteredData = sitesData.filter(item => {
    //         return item.id!==id
    //     });
    //     if (filteredData) {
    //         setCentreSites(filteredData);
    //         console.log(filteredData)
    //     }
    // }
    const handleFileChangeOne = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };
    const handleFileChangeTwo = (e) => {
        const file = e.target.files[0];
        setSelectedFileTwo(file);
    };
    const addContratModal = () => {
        setMembre_id(0)
        setContratId(0);
        setPourcentage('');
        setSite_id(0);
        setPath("");
        setPathTwo("");
        setVisible(true);
        setSitecentre_id(null);
        setcentresoins_id(null)
    }
    const UpdateContrat = async (id) => {
        try {

            if (activeIndex == 0) {
                const formData = new FormData();
                const path_url = URL_MAIN + path;
                const path_url_two = URL_MAIN + path_two;

                formData.append('site_id', site_id);
                formData.append('membre_id', membre_id);
                formData.append('id_user', user_id);
                formData.append('pourcentage', pourcentage);

                // Handle file uploads or keep the existing path
                if (selectedFile && selectedFile !== "") {
                    formData.append('file_one', selectedFile);
                } else {
                    formData.append('file_one', path_url); // Send the existing path if no new file is selected
                }

                if (selectedFileTwo && selectedFileTwo !== "") {
                    formData.append('file_two', selectedFileTwo);
                } else {
                    formData.append('file_two', path_url_two); // Send the existing path if no new file is selected
                }

                const res = await axios.post(URL_AXIOS + `update_contrat/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (res.data.status === 200) {
                    lister_contrat();
                    setVisibleM(false);
                } else {
                    alert("Error:  ddzdz");
                }
            } else if (activeIndex == 1) {
                if (sitecentre_id?.id && centresoins_id?.id && pourcentage) {
                    const formData = new FormData();
                    formData.append("site_id", sitecentre_id.id);
                    formData.append("centresoins_id", centresoins_id.id);
                    formData.append("pourcentage", pourcentage);
                    formData.append("file_one", selectedFile || "");
                    formData.append("file_two", selectedFileTwo || "");

                    const response = await axios.post(`${URL_AXIOS}updateSitesCentresSoins/${id}`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });

                    if (response.data.status == 200) {
                        setVisibleM(false);
                        liste_site_centre();
                    } else {
                        console.error(`Erreur: ${JSON.stringify(response?.data || "Aucune donnée reçue")}`);

                    }
                } else {
                    handleValidationError();
                }

            }

        } catch (error) {
            alert("Error: " + error.message);
        }
    };



    const addContrat = async () => {
        try {
            // Validate based on activeIndex
            if (activeIndex === 0) {
                if (site_id && membre_id && pourcentage) {
                    const formData = new FormData();
                    formData.append("site_id", site_id);
                    formData.append("membre_id", membre_id);
                    formData.append("id_user", user_id);
                    formData.append("file_one", selectedFile || "");
                    formData.append("file_two", selectedFileTwo || "");
                    formData.append("pourcentage", pourcentage);

                    const response = await axios.post(`${URL_AXIOS}addMembresSites`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });

                    if (response.data.status === 200) {
                        lister_contrat();
                        setVisible(false);
                    } else {
                        alert("Une erreur s'est produite lors de l'ajout des membres au site.");
                    }
                } else {
                    handleValidationError();
                }
            } else {
                if (sitecentre_id?.id && centresoins_id?.id && pourcentage) {
                    const formData = new FormData();
                    formData.append("site_id", sitecentre_id.id);
                    formData.append("centresoins_id", centresoins_id.id);
                    formData.append("pourcentage", pourcentage);
                    formData.append("file_one", selectedFile || "");
                    formData.append("file_two", selectedFileTwo || "");

                    const response = await axios.post(`${URL_AXIOS}addSitesCentresSoins`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });

                    if (response.data.status == 200) {
                        setVisible(false);
                        liste_site_centre();
                    } else {
                        console.error(`Erreur: ${JSON.stringify(response?.data || "Aucune donnée reçue")}`);

                    }
                } else {
                    handleValidationError();
                }
            }
        } catch (error) {
            console.error("Une erreur est survenue :", error);
            alert(`Erreur : ${error.message}`);
        }
    };

    // Helper function for validation errors
    const handleValidationError = () => {
        setVisible(false);
        Swal.fire({
            title: "Erreur",
            text: "Veuillez renseigner tous les champs obligatoires !",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                setVisible(true);
            }
        });
    };


    // const addContrat2 = async () => {
    //     try {

    //         /*const formData = new FormData();
    //         formData.append('site_id', site_id);
    //         formData.append('membre_id', membre_id);
    //         formData.append('id_user', user_id);
    //         formData.append('file_one', selectedFile);
    //         formData.append('file_two', selectedFileTwo);
    //         formData.append('pourcentage', pourcentage);
    //         */

    //         if (activeIndex == 0) {

    //             if (site_id !== "" && membre_id !== "" && pourcentage !== "") {
    //                 const data = {
    //                     site_id: site_id,
    //                     membre_id: membre_id,
    //                     id_user: user_id,
    //                     file_one: (selectedFile === null || selectedFile === "") ? "" : selectedFile,
    //                     file_two: (selectedFileTwo === null || selectedFileTwo === "") ? "" : selectedFileTwo,
    //                     pourcentage: pourcentage

    //                 }
    //                 const res = await axios.post(URL_AXIOS + "addMembresSites", data, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                     },
    //                 });
    //                 if (res.data.status === 200) {
    //                     lister_contrat();
    //                     setVisible(false);

    //                 } else {
    //                     alert("Error fejfbeifb");
    //                 }
    //             } else {
    //                 setVisible(false)
    //                 Swal.fire({
    //                     title: "Erreur",
    //                     text: "Veuillez renseigner tous les champs obligatoires!",
    //                     icon: "warning",
    //                 }).then((result) => {
    //                     if (result.isConfirmed) {
    //                         setVisible(true)
    //                     }
    //                 });
    //             }
    //         } else {

    //             if (sitecentre_id && centresoins_id && pourcentage) {

    //                 const data = {
    //                     site_id: sitecentre_id.id,
    //                     centresoins_id: centresoins_id.id,
    //                     pourcentage: pourcentage,
    //                     file_one: selectedFile || "",
    //                     file_two: selectedFileTwo || "",
    //                 }
    //                 const res = await axios.post(URL_AXIOS + "addSitesCentresSoins", data, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                     },
    //                 });
    //                 if (res.data.status === 200) {
    //                     // lister_contrat();
    //                     setVisible(false);
                  

    //                 } else {
    //                     console.log("Error fejfbeifb" + JSON.stringify(selectedFile));
    //                 }
    //             } else {
    //                 setVisible(false)
    //                 Swal.fire({
    //                     title: "Erreur",
    //                     text: "Veuillez renseigner tous les champs obligatoires!",
    //                     icon: "warning",
    //                 }).then((result) => {
    //                     if (result.isConfirmed) {
    //                         setVisible(true)
    //                     }
    //                 });
    //             }
    //         }


    //     } catch (error) {
    //         alert("Error" + error.message)
    //     }
    // }
    // const AddConfirmContrat = () => {
    //     try {
    //         Swal.fire({
    //             title: "Enregistrement",
    //             text: "Êtes-vous sûr de vouloir enregistrer ce contrat",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Oui",
    //             cancelButtonText: "Non"
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 Swal.fire({
    //                     title: "Réussi !",
    //                     text: "Le contrat a été enregistré avec succès !",
    //                     icon: "success"
    //                 });
    //                 addContrat()
    //             }
    //         });
    //     } catch (error) {

    //     }
    // }
    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'membres');
            if (response.data.status === 200) {
                setMembres(response.data.membres);

            }
        } catch (Error) {

        }
    }


    const liste_site_centre = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_sites_centresSoins');
            if (response.data.status === 200) {
                setCentreSitesData(response.data.vue_contrat_site_centresoins);

            }
        } catch (Error) {

        }
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }

    }
    // const prevPage = () => {
    //     if (currentPage !== 1) {
    //         setCurrentPage(currentPage - 1)
    //     }
    // }

    // const nextPage = () => {
    //     if (currentPage !== npage) {
    //         setCurrentPage(currentPage + 1)
    //     }
    // }
    // const changeCPage = (id) => {
    //     setCurrentPage(id)
    // }


    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_membres_sites');
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);
                setLoading(false)

            }
        } catch (Error) {

        }
    };

    const delete_contrat = async (id) => {
        try {

            const response = await axios.post(URL_AXIOS + `delete_contrat/${id}`);
            if (response.data.status === 200) {
                lister_contrat();

            }
        } catch (Error) {

        }
    };

    const delete_contrat_sitescentre = async (id) => {
        try {

            const response = await axios.post(URL_AXIOS + `deleteSitesCentreSoins/${id}`);
            if (response.data.status === 200) {
                liste_site_centre();
                Swal.fire({
                    title: "Réussi !",
                    text: "Le contrat site centre de soin a été supprimé avec succès !",
                    icon: "success"
                });

            } else {

            }
        } catch (Error) {

        }
    };
    const handleInputPourcentage = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;

        if (value !== "") {
            setPourcentage(value)

        } else {
            setPourcentage("")
        }
        if (regex.test(value)) {
            setPourcentage(value);
        } else {
            setPourcentage("");
        }
    };
    const deleteConfirmMrc = (data) => {
        try {
            Swal.fire({
                title: "Question ?",
                text: "Êtes-vous sûr de vouloir supprimer ce contrat",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {

                    if (activeIndex == 0) {
                        delete_contrat(data.id);
                        lister_contrat();
                    } else if (activeIndex == 1) {
                        delete_contrat_sitescentre(data.id);

                    }

                }
            });
        } catch (error) {

        }
    }
    const liste_site_contrat = async (data) => {
        try {
            if (activeIndex == 0) {
                const response = await axios.get(URL_AXIOS + `get_sites_membres_by_id/${data.id}`);
                setMembre_id(response.data.vue_sites_membres.membre_id);
                setContratId(response.data.vue_sites_membres.id)
                setSite_id(response.data.vue_sites_membres.site_id);
                setPourcentage(response.data.vue_sites_membres.pourcentage);
                let chemin = response.data.vue_sites_membres.chemin_fichier_one_name;
                let chemin_two = response.data.vue_sites_membres.chemin_fichier_two_name;
                setPath(chemin);
                setPathTwo(chemin_two);
            } else {
                onSelectSiteLoad(data.site_id)
                setSitecentre_id(data.site_id_cs);
                onSelectSiteId(data.site_id_cs)
                setPourcentage(data.pourcentage);
                setContratId(data.id);
                // Find the site matching the societe
                const filteredData = sitesData.filter(item => item.societe === data.societe);
                const obj = filteredData.length ? filteredData[0] : {};
                setSitecentre_id(obj);

                // // Find the member matching the id_contrat
                const filteredDataMembre = sitesData.filter(item => (item.societe === data.societe_cs));
                const objMembre = filteredDataMembre.length ? filteredDataMembre[0] : {};
                setcentresoins_id(objMembre);
            }

            setVisibleM(true);

        } catch (Error) {

        }
    }

    const handleDownload = async (e, value, value2) => {
        e.preventDefault();

        const downloadFile = (fileName, url) => {
            return new Promise((resolve) => {
                const link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link); // Append link to the body
                link.target = '_blank';
                link.click();
                document.body.removeChild(link); // Remove link from the body
                resolve();
            });
        };

        try {
            if (value !== null) {
                await downloadFile('Example-PDF-File', URL_MAIN + value);
            }
            if (value2 !== null) {
                // Increase delay to ensure the browser has time to handle the first download
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await downloadFile('Example-PDF-File2', URL_MAIN + value2);
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    };
    const downloadFile = (pdfUrl) => {

        if (pdfUrl !== "" || pdfUrl !== null) {
            const pdfUrlSet = URL_MAIN + pdfUrl;
            const link = document.createElement("a");
            link.href = pdfUrlSet;
            link.download = "document.pdf"; // specify the filename
            link.target = '_blank'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {

        }


    };
    const DownloadFiles = (pdfUrl, pdfUrl2) => {
        downloadFile(pdfUrl);
        if (pdfUrl2 !== "" || pdfUrl2 !== null) {
            downloadFile(pdfUrl2);
        }

    };

    const liste_centre_des_soins = async (city) => {
        try {
            const response = await axios.get(URL_AXIOS + 'centres');
            if (response.data.status == 200) {
                setSitesData(response.data.sites);


            }
        } catch (Error) {

        }

    }
    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => liste_site_contrat(data)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{ marginLeft: 10 }} onClick={() => deleteConfirmMrc(data)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
                <button type='button' disabled={(data.chemin_fichier_one_name == null && data.chemin_fichier_two_name == null) ? true : false} style={{ marginLeft: 10 }} onClick={(e) => handleDownload(e, data.chemin_fichier_one_name, data.chemin_fichier_two_name)} className='btn btn-primary'>
                    <i className='fa fa-download'></i>
                </button>
            </div>
        )
    }

    useEffect(() => {
        onSelectSiteId(sitecentre_id)
    }, [sitecentre_id]);
    useEffect(() => {
        liste_site();
        liste_membres();
        liste_centre_des_soins();
        liste_site_centre();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
        lister_contrat();
    }, []);
    return (
        <div>

            <Dialog header="Contrats" headerClassName='bg-[#4ECDC4] text-white' visible={visibleM} style={{ width: '50vw' }} onHide={() => setVisibleM(false)}>
                <div className='container-fluid mt-[20px]'>
                    {activeIndex == 1 && <div className='row'>
                        <div className='col col-md-12'>

                            <Dropdown
                                value={sitecentre_id}
                                name='site_id'
                                onChange={(e) => setSitecentre_id(e.value)}
                                options={sitesData}
                                optionLabel="societe"
                                optionValue='id'
                                placeholder="Sélectionnez site *"
                                filter className="w-full border-2 "
                                style={{ fontWeight: 'bold', marginTop: "-6px" }}
                                emptyMessage='Aucun site disponible'
                            />
                        </div>

                    </div>}

                    {activeIndex == 1 && <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <Dropdown
                                value={centresoins_id}
                                name='site_id'
                                onChange={(e) => setcentresoins_id(e.value)}
                                options={centreSites}
                                optionLabel="societe"
                                optionValue='id'
                                placeholder="Sélectionnez centre de centre de soin *"
                                filter className="w-full border-2 "
                                style={{ fontWeight: 'bold', marginTop: "-6px" }}
                                emptyMessage='Aucun centre de soins disponible'
                            />
                        </div>

                    </div>}
                    {activeIndex == 0 && <div className='row'>
                        <div className='col col-md-12'>

                            <select className='form-control' value={membre_id} onChange={(e) => setMembre_id(e.target.value)}>
                                <option>Veuillez sélectionner le membre</option>
                                {membres.map((m) => (
                                    <option value={m.id}>{m.nom + ' ' + m.prenom}</option>
                                ))}

                            </select>
                        </div>


                    </div>}
                    {activeIndex == 0 && <div className='row'>
                        <div className='col col-md-12'>
                            <select className='form-control' value={site_id} onChange={(e) => setSite_id(e.target.value)}>
                                <option>Veuillez sélectionner le site</option>
                                {sites.map((s) => (
                                    <option value={s.id}>{s.societe}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='text' value={pourcentage} onChange={handleInputPourcentage} placeholder='veuillez saisir le pourcentage' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='file' className='form-control' onChange={handleFileChangeOne} />
                            {
                                path && (
                                    <a href={URL_MAIN + path} target="_blank">
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <img src="https://logowik.com/content/uploads/images/638_pdf_icon.jpg"
                                                style={{ width: 80 }}
                                            />
                                            <h5 style={{ marginTop: 23 }}>{path}</h5>
                                        </div>
                                    </a>
                                )
                            }


                        </div>
                    </div>
                    <div className='row'>

                        <div className='col col-md-12'>
                            <input type='file' className='form-control' onChange={handleFileChangeTwo} />
                            {
                                path_two && (
                                    <a href={URL_MAIN + path_two} target="_blank">
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <img src="https://logowik.com/content/uploads/images/638_pdf_icon.jpg"
                                                style={{ width: 80 }}
                                            />
                                            <h5 style={{ marginTop: 23 }}>{path_two}</h5>
                                        </div>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                    <div className='row mt-[20px]'>
                        <div className='col col-md-12'>
                            <button type='button' className='btn btn-warning form-control'
                                onClick={() => UpdateContrat(contratId)}
                            >Mettre à jour</button>

                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog header="Contrats" headerClassName='bg-[#4ECDC4] text-white' visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='container-fluid mt-[20px]'>
                    {activeIndex == 1 && <div className='row'>
                        <div className='col col-md-12'>

                            <Dropdown
                                value={sitecentre_id}
                                name='site_id'
                                onChange={(e) => setSitecentre_id(e.value)}
                                options={sitesData}
                                optionLabel="societe"
                                optionValue='id'
                                placeholder="Sélectionnez site *"
                                filter className="w-full border-2 "
                                style={{ fontWeight: 'bold', marginTop: "-6px" }}
                                emptyMessage='Aucun site disponible'
                            />
                        </div>

                    </div>}

                    {activeIndex == 1 && <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <Dropdown
                                value={centresoins_id}
                                name='site_id'
                                onChange={(e) => setcentresoins_id(e.value)}
                                options={centreSites}
                                optionLabel="societe"
                                optionValue='id'
                                placeholder="Sélectionnez centre de centre de soin *"
                                filter className="w-full border-2 "
                                style={{ fontWeight: 'bold', marginTop: "-6px" }}
                                emptyMessage='Aucun centre de soins disponible'
                            />
                        </div>

                    </div>}
                    {activeIndex == 0 && <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <select className='form-control' value={membre_id} onChange={(e) => setMembre_id(e.target.value)}>
                                <option>Veuillez sélectionner le membre *</option>
                                {membres.map((m) => (
                                    <option value={m.id}>{m.nom + (m.prenom ? " " + m.prenom : "")}</option>
                                ))}

                            </select>
                        </div>
                    </div>}
                    {activeIndex == 0 && <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <select className='form-control' value={site_id} onChange={(e) => setSite_id(e.target.value)}>
                                <option>Veuillez sélectionner le site *</option>
                                {sites.map((s) => (
                                    <option value={s.id}>{s.societe}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    <div className='row mt-[10px]'>
                        <div className='col col-md-5'>
                            <input type='text' value={pourcentage} onChange={handleInputPourcentage} placeholder='veuillez saisir le pourcentage *' className='form-control' />
                        </div>
                    </div>
                    <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <input type='file' name='f1' className='form-control' accept=".pdf" onChange={handleFileChangeOne} />
                        </div>
                    </div>
                    <div className='row mt-[10px]'>
                        <div className='col col-md-12'>
                            <input type='file' name='f2' className='form-control' accept=".pdf" onChange={handleFileChangeTwo} />
                        </div>
                    </div>
                    <div className='row mt-[20px]'>
                        <div className='col col-md-12'>
                            <button type='button' className='btn btn-info form-control'
                                onClick={() => addContrat()}
                            >Enregistrer</button>
                        </div>
                    </div>
                    <br />
                </div>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper ">
                    <div className='row mt'>
                        <div className='col col-md-12'>
                            <Button className='btn btn-info ml-[10px]' icon="pi pi-plus" onClick={() => addContratModal()} label={activeIndex == 0 ? "Nouveau contrat site / membre" : "Nouveau contrat site / centre de soins"} />
                        </div>
                    </div>
                    <TabView activeIndex={activeIndex} onTabChange={handleTabView} className='mt-[10px]'>
                        <TabPanel header="LISTE CONTRATS SITE / MEMBRE" headerClassName={activeIndex == 0 ? "bg-[#64c3c2] text-white" : "bg-[#fff] text-black"}>
                            <div class="row " style={{ padding: 10 }}>
                                <DataTable value={siteMembre} paginator emptyMessage="Aucun contrat disponible!" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '70rem' }} className="custom-datatable">
                                    <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px", padding: '0 10px' }} field='id' style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field={'societe'} header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                                    <Column field={(data) => { return <div>{data.nom + (data.prenom ? " " + data.prenom : "")}</div> }} header="NOM(S) ET PRENOM(S)" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    {/* <Column field='pays' header="PAYS" style={{ width: 'auto', height: '40px' }}></Column> */}
                                    <Column field='ville' header="VILLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='specialite' header="SPECIALITE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='pourcentage' header="%" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px', padding: '0 10px' }}></Column>
                                </DataTable>
                                {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>NOM ET PRENOM</th>
                                    <th style={{ color: 'red' }}><center>SEXE</center></th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}>TELEPHONE</th>
                                    <th style={{ color: 'red' }}>PAYS</th>
                                    <th style={{ color: 'red' }}>VILLE</th>
                                    <th style={{ color: 'red' }}>SPECIALITE</th>
                                    <th style={{ color: 'red' }}>%</th>
                                    <th style={{ color: 'red' }}>DATE</th>

                                    <th colSpan={3} style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.societe}</td>
                                            <td>{si.nom + ' ' + si.prenom}</td>
                                            <td><center>{si.sexe}</center></td>
                                            <td>{si.email}</td>
                                            <td>{si.telephone}</td>
                                            <td>{si.pays}</td>
                                            <td>{si.ville}</td>
                                            <td>{si.specialite}</td>
                                            <td>{si.pourcentage}</td>
                                            <td>{si.created_at}</td>
                                            <td><center><button type='button' onClick={() => liste_site_contrat(si.id)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => deleteConfirmMrc(si.id)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => DownloadFiles(si.chemin_fichier_one_name, si.chemin_fichier_two_name)} className='btn btn-primary'>
                                                <i className='fa fa-download'></i>
                                            </button>
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                                {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                            </div>
                        </TabPanel>
                        <TabPanel header="LISTE CONTRATS SITE / CENTRE DE SOINS" headerClassName={activeIndex == 1 ? "bg-[#64c3c2] text-white" : "bg-[#fff] text-black"}>
                            <div class="row " style={{ padding: 10 }}>
                                <DataTable value={centreSitesData} paginator emptyMessage="Aucun contrat disponible!" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '70rem' }} className="custom-datatable">
                                    {/* <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center', padding: '0 10px' }} headerStyle={{ marginLeft: "100px", padding: '0 10px' }} field='id' style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column>
                                     */}
                                    <Column field={'societe'} header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                                    <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='ville' header="VILLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field={'societe_cs'} header="CENTRE SOIN" style={{ width: 'auto', height: '40px' }}></Column>
                                    <Column field={'email_sc'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='telephone_sc' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column field='pourcentage' header="%" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>

                                    <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                                    <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px', padding: '0 10px' }}></Column>
                                </DataTable>

                            </div>
                        </TabPanel>

                    </TabView>

                </section>
            </section>}
        </div>
    );
}

export default Contrats;